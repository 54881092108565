// @import 'bootstrap/variables';

// utils
.can-click {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.hidden-xl {
  @include media-breakpoint-only(xl) {
    display: none;
  }
}

.hidden-lg {
  @include media-breakpoint-only(lg) {
    display: none;
  }
}

.hidden-md {
  @include media-breakpoint-only(md) {
    display: none;
  }
}

.hidden-sm {
  @include media-breakpoint-only(sm) {
    display: none;
  }
}

.hidden-xs {
  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.modal-backdrop-light {
  background: map-get($map: $theme-colors, $key: light);
  opacity: 1;

  &.modal-backdrop.show {
    opacity: 1;
  }
}

.invalid-customError {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f85032;
}