.paginationContainer {
    margin: 10px 0px 10px 20px;
    ul {
        display: flex;
        flex-direction: row;
        border: solid 0.5px #ccc;
        list-style-type: none;
        text-align: left;
        margin: 0;
        padding: 0;
        li {  
            display: flex;
            a:hover{
                text-decoration: none;
            }
            a {
                border-right: 0.5px solid #ccc; 
                background: #fff;
                color: blue;
                padding: 10px 20px;
                cursor: pointer;
            }
        }
        .selected { 
            a {
                background: blue; 
                color: #fff;
            }
        }
        .disabled{
            a {
                background: #fff; 
                color: #ccc; 
                cursor:default ;
            }
        }
    }
}
